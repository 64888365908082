<template>
  <div class="tab-pane active" id="sms-stats">
    <div class="card-body">
      <div class="loading min-h-300" v-if="loading">
        <LoadingAnim />
      </div>
      <div v-else class="table-responsive">
        <TableComponent :fields="fields" :items="usageRecords" :busy="loading">
          <template #cell(price)="slotProps">
            {{ slotProps.data | currency4D }}
          </template>
        </TableComponent>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import Tab from '@/directives/Tab';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import TableComponent from '@/components/TableComponent.vue';

export default {
  name: 'SmsReport',
  components: {
    LoadingAnim,
  },
  directives: {
    Tab,
  },
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      fields: [
        'description',
        'count',
        'price',
      ],
      fetchTimeout: 0,
      loading: false,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('01/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      usageRecords: [],
      form: {
        category: 'sms',
        date: {
          gte: moment().format('YYYY-MM-01'),
          lte: moment().format('YYYY-MM-DD'),
        },
      },
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.loading = true;

      this.$store
        .dispatch('fetchUsage', {
          filter: {
            ...this.filters,
            category: 'whatsapp',
            date: {
              gte: this.startDate,
              lte: this.endDate,
            },
          },
          page,
        })
        .then((response) => {
          this.usageRecords = response.data;
          this.pages = response.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-responsive {
  min-height: 150px;
}
</style>
